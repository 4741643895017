:root { 
  --alto: #d9d9d933;
  --alto-2: #d9d9d980;
  --athens-gray: #eeeef2;
  --black: #000000;
  --black-2: #000000cc;
  --black-haze: #f7f7f7;
  --black-squeeze: #f3f8f9;
  --burnt-sienna: #e87d6b;
  --cashmere: #ebc3a0;
  --champagne: #f9ebc8;
  --dell: #3e731e;
  --fantasy: #faf3f4;
  --link-water: #d4edf4;
  --orient: #036280;
  --purple-mountains-majesty: #9c85a8;
  --purple-plum: #983dbb;
  --shakespeare: #52b3d9;
  --ship-gray: #3a3847;
  --ship-gray-2: #3a3847b2;
  --strikemaster: #956a7d;
  --swans-down: #dde4eb;
  --tamarillo: #ac1015;
  --tan: #d1a78b;
  --white: #ffffff;
  --white-2: #ffffff80;
  --merino: #eff4ea;
  --aqua-haze: #ebf3f5;
  --pink-pearl: #f5f1f9;
 
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 12px;
  --font-size-xl: 20px;
  --font-size-xxl: 22px;
  --font-size-xxxl: 28px;
  --font-size-xxxxl: 36px;
 
  --font-family-public_sans: "Public Sans", Helvetica;
  --font-family-quicksand: "Quicksand", Helvetica;
  --font-family-raleway: "Raleway", Helvetica;
}
.quicksand-normal-white-22px {
  color: var(--white);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.quicksand-medium-ship-gray-16px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.quicksand-normal-ship-gray-16px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.quicksand-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.quicksand-medium-ship-gray-18px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.publicsans-bold-black-16px {
  color: var(--black-2);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.quicksand-normal-ship-gray-18px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.quicksand-medium-ship-gray-12px {
  color: var(--ship-gray-2);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.quicksand-normal-white-8px {
  color: var(--white);
  font-family: var(--font-family-quicksand);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.publicsans-black-ship-gray-20px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 900;
}

.raleway-bold-orient-18px {
  color: var(--orient);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-ship-gray-36px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.publicsans-extra-bold-ship-gray-20px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 800;
}

.quicksand-bold-ship-gray-18px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-ship-gray-20px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-bold-ship-gray-22px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-black-22px {
  color: var(--black-2);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-ship-gray-28px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.publicsans-extra-bold-white-20px {
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 800;
}

.publicsans-extra-bold-orient-20px {
  color: var(--orient);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 800;
}

.publicsans-thin-ship-gray-20px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 100;
}

.quicksand-normal-ship-gray-22px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.quicksand-bold-orient-16px {
  color: var(--orient);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.quicksand-medium-ship-gray-20px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.quicksand-medium-ship-gray-14px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.publicsans-extra-bold-ship-gray-22px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 800;
}

.publicsans-bold-white-28px {
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-normal-black-22px {
  color: var(--black-2);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.quicksand-bold-white-28px {
  color: var(--white);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-bold-ship-gray-28px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-medium-ship-gray-28px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}

.quicksand-normal-black-16px {
  color: var(--black-2);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.raleway-bold-orient-16px {
  color: var(--orient);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.quicksand-normal-black-14px {
  color: var(--black-2);
  font-family: var(--font-family-quicksand);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.publicsans-extra-bold-white-28px {
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}

.publicsans-extra-bold-orient-28px {
  color: var(--orient);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 800;
}

.quicksand-normal-white-22px-2 {
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.quicksand-normal-dell-22px {
  color: var(--dell);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.publicsans-bold-white-62px {
  font-family: var(--font-family-public_sans);
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-ship-gray-62px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-purple-mountains-majesty-62px {
  color: var(--purple-mountains-majesty);
  font-family: var(--font-family-public_sans);
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-orient-28px {
  color: var(--orient);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-purple-mountains-majesty-28px {
  color: var(--purple-mountains-majesty);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.raleway-medium-ship-gray-16px {
  color: var(--ship-gray);
  font-family: var(--font-family-raleway);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.publicsans-black-ship-gray-36px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 900;
}

.publicsans-bold-white-54px {
  font-family: var(--font-family-public_sans);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-ship-gray-54px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-purple-mountains-majesty-54px {
  color: var(--purple-mountains-majesty);
  font-family: var(--font-family-public_sans);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
}

.publicsans-bold-ship-gray-48px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

.publicsans-semi-bold-orient-28px {
  color: var(--orient);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 600;
}

.quicksand-bold-orient-18px {
  color: var(--orient);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.quicksand-bold-white-36px {
  color: var(--white);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-bold-ship-gray-36px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.quicksand-medium-ship-gray-36px {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 500;
}

.publicsans-bold-ship-gray-22px {
  color: var(--ship-gray);
  font-family: var(--font-family-public_sans);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
