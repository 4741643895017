@import url("https://fonts.googleapis.com/css?family=Public+Sans:700,900,600,800,100|Quicksand:400,700,600,500|Raleway:400,700,600,500");

.App {
  text-align: center;
}

.App-logo {
  /* height: 20vmin; */
  /* width: 50vmin; */
  pointer-events: none; 
  height: 80px;
  object-fit: cover;
  width: 200px;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

body {
  /* font-family: "Public Sans", "Quicksand", Helvetica; */
  /* background: #fafafa; */
  margin: 0px;
}

/*-----------------------------------
  Home page
----------------------------------- */
.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  /* margin: 0px 100px; */
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.screen {
  align-items: start;
  background-color: #ffffff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 70vw;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-9 {
  /* align-items: center; */
  min-height: 100vh; 
  width: 70vw;
}

.rectangle-purple {
  background-color:var(--purple-plum); /*  #71008e; */
  height: 16px;
  margin-left: 30px;
  /* margin-top: -10px; */
  min-width: 72px;
}

.rectangle-white {
  background-color:var(--white); /*  #71008e; */
  height: 16px;
  margin-left: 30px;
  /* margin-top: -10px; */
  min-width: 72px;
}

/*--------------------------------------
About Us page
---------------------------------------- */
.nav-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* align-items: center; */
  width: 100%;
  margin-top: -1em;
}

.about-us-1 {
  /* font-family: var( --font-family-quicksand );
  font-size: 18px or var(--font-size-l);
  font-weight: 700; 
  font-style: normal; 
  color: var(--ship-gray); */
  letter-spacing: -0.68px;
  line-height: normal;
  margin-left: 30px;
  margin-top: 12px;
  min-height: 23px;
  min-width: 72px;
}

.css-aboutus {
  display: flex;
  position: relative;
  flex-flow: column wrap;
  overflow: auto;
  padding: 30px 20px;
  margin: -10px;
  width: 100%;
}

.css-top-content {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
  flex-shrink: 0;
  height: 100px;
  /* border-radius: 4px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.nav-link-clickable {
  /* display: flex !important; */
  cursor: pointer;
}

.title-container {
  margin: 1.0rem 0rem;
  -webkit-text-stroke: 1px var(--white);
  line-height: normal;
}
.subtitle-container {
  margin: 1.0rem 0rem;
}

.title {
  font-family: var( --font-family-public_sans );
  color: var(--ship-gray);
  font-size: 2.5rem; 
  font-weight: 800;
  font-style: normal;
}

.content-text {
  text-align: center;
  margin: 1.0rem 3.0rem;
}

p {
  font-family: var( --font-family-quicksand );
  font-size: 1.5em;
}

.p-1 {
  font-family: var( --font-family-quicksand );
  font-size: 1em;
}

.h2-title{
  font-family: var( --font-family-public_sans );
  font-size: 2.0rem;
  color: var(--ship-gray); 
  font-weight: 700;
  font-style: normal;
 }


 @media only screen and (max-width: 1025px) {
  .screen {
    width: 95vw;
  }

  .flex-col-9 {
    align-items: center;
    min-height: 100vh; /* 3737 */
    width: 95vw;
  }

  .App-logo {
    pointer-events: none; 
    height: 60px;
    object-fit: cover;
    width: 150px;
  }

  .title-container {
    /* margin: 20px 50px; */
    margin: 1.0rem 0.25rem;
  }

  .title {
    font-size: 2.0rem; 
  }

  .content-text {
    text-align: center;
    margin: 1.0rem 1.0rem;
  }

  .h2-title{
    font-family: var( --font-family-public_sans );
    font-size: 1.5rem;
    color: var(--ship-gray); 
    font-weight: 700;
    font-style: normal;
  }
}

@media only screen and (max-width: 767px) {
  .quicksand-normal-white {
    color: var(--white);
    font-family: var(--font-family-quicksand);
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
  }
}

.top-img-container {
  width: 100%;
  pointer-events: none; 
}

.responsive-img {
  width: 100%;
  height: auto;
}

.card-container {
  /* display: flex;
  align-items: center; */
  margin: 20px 20px;
  box-shadow: 1px 4px 20px #3a384729;
  overflow: hidden;
  min-height:520px;
  height: 100%;
}

.card-img-container {
  display: flex;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  /* padding: 30px 0px 40px 0px; */
  pointer-events: none; 
  justify-content: center;
}

.card-body {
  padding: 1.5rem;
  text-align: center;
}

.card-title {
  font-family: var( --font-family-public_sans );
  color: var(--ship-gray) !important;
  font-size: 1.3rem; 
  font-weight: 800;
  font-style: normal;
}

.row-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3.0rem 4.0rem;
  gap: 3.0rem;
}

.fact-container {
  align-items: center;
  justify-content: center;
  background-color: var(--athens-gray);
  padding: 20px 20px;
}

.fact-card-container {
  overflow: hidden;
  /* min-width: 9rem; */
  background-color: var(--athens-gray)!important;
  min-height:250px;
  height: 100%;
}

.fact-title {
  letter-spacing: -0.22px;
  line-height: 30px;
  position: absolute;
  font-family: var(--font-family-public_sans);
  font-size: 1.1rem; 
  font-weight: 800;
  font-style: normal;
  color: var(--orient) !important;
}

.p-card1 {
  font-family: var( --font-family-quicksand );
  font-size: 1em;
  padding-top: 2rem;
  text-align: left;
  font-weight: 500;
  color: var(--ship-gray)
}

.p-card2 {
  font-family: var( --font-family-quicksand );
  font-size: 0.8em;
  padding-top: 2rem;
  text-align: left;
  font-weight: 500;
  color: var(--ship-gray-2)
}

.img-line {
  width: 2px !important;
  height: 41px !important;
  position: absolute;
}

.fact-container-orient {
  align-items: center;
  justify-content: center;
  background-color: var(--orient);
  padding: 20px 20px;
}

.increased-demand-for-social-care-2 {
  text-align: center;
  margin: 1.0rem 1.0rem;
}

.quote-container {
  color: var(--white);
  font-family: var(--font-family-public_sans);
  font-size: 2em;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  white-space: pre-wrap;
  letter-spacing: 0.015em;
  line-height: 1.6em;
  text-transform: none;
  box-sizing: border-box;
  margin: 0;
  padding: 1em 1em 0;
}

blockquote:before {
  content: "“";
  font-size: 6em;
  line-height: 0.1em;
  vertical-align: -0.4em;
  color: var(--white-2);
}
blockquote:after {
  content: "”";
  font-size: 6em;
  line-height: 0.1em;
  margin-right: 0.1em;
  vertical-align: -0.5em;
  color: var(--white-2);
}

.card-text-container {
  margin: 20px 20px;
  /* box-shadow: 1px 4px 20px #3a384729; */
  overflow: hidden;
  /* min-height:320px; */
  /* height: 100%; */
}

.card-text-body {
  padding: 1.5rem;
  text-align: left;
}

.card-link {
  color: var(--orient);
  font-family: var(--font-family-quicksand);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
}

.arrow-1 {
  height: 12px;
  margin-left: 4px; 
  width: 15px;
}

.line-89 {
  height: 1px;
  left: 0;
  width: 89px;
}

.media-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-video-img-container {
  height: 100%;
  padding: 20px 20px 40px;
  width: 100%;
  /* box-shadow: 1px 4px 20px #3a384729; */
}

.media-content {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  pointer-events: "auto";
  -webkit-box-shadow: 10px 10px 5px 0px #3a384729;
  -moz-box-shadow: 10px 10px 5px 0px #3a384729;
  box-shadow: 10px 10px 5px 0px #3a384729;
}

.team-card-container {
  background-color: var(--athens-gray);
  margin: 20px 20px;
  overflow: hidden;
}

.team-img-container {
  display: flex;
  background: transparent;
  width: 100%;
  pointer-events: none; 
  justify-content: center;
}

/*-----------------------------------
  Footer
----------------------------------- */

.footer-container {
  background-color: var(--ship-gray);
  padding: 3em 0.0em;
}

.quicksand-normal-white {
  color: var(--white);
  font-family: var(--font-family-quicksand);
  font-size: 0.8em;
  font-style: normal;
  font-weight: 400;
}

.footer-img-container {
  display: flex;
  background: transparent;
  width: 100%;
  pointer-events: none; 
  justify-content: center;
}

.footer {
  color: #fff;
  text-align: center;
}

.footer a {
  color: #fff;
  text-decoration: none;
  display: flex;
  margin: 10px 0;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-divider {
  border: 0;
  height: 2px;
  background: #cfcece;
  margin: 20px 0;
}

.footer-divider-vertical {
  border: 0;
  width: 2rem;
  height: 3rem;
  margin: 0 0.5rem;
  padding-top: 0.5rem;
  align-items: center;
}

.footer-padding {
  padding-left: 10rem;
  padding-right: 10rem;
  padding-bottom: 2.0rem;
}

.span1-5 {
  line-height: 30rem;
}

/*-----------------------------------
  Our Solutions page
----------------------------------- */

.account-container {
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  margin-left: 0em !important;
  width: 100%;
}

.account-text-container {
  background-color: transparent !important;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.account-text-body {
  /* padding: 0.5rem; */
  text-align: left;
}

.account-title-container {
  display: flex;
  /* margin: 1.0rem 1.0rem; */
  flex-direction: row;
  gap: 0.4rem;
}

.account-title {
  font-family: var( --font-family-quicksand );
  color: var(--ship-gray) !important;
  font-size: 1.5rem; 
  font-weight: 800;
  font-style: normal;
  align-self: center;
  /* justify-content: center; */
}

.account-subtitle {
  font-family: var( --font-family-quicksand );
  color: var(--ship-gray) !important;
  font-size: 1.5rem; 
  font-weight: 600;
  font-style: normal;
  align-self: center;
  /* justify-content: center; */
}

.check-1 {
  height: 12px;
  width: 18px;
}

.account-video-img-container {
  height: 100%;
  padding: 20px 20px 40px;
  width: 100%;
}

.media-content1 {
  width: 32rem;
  height: 16rem;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  pointer-events: "auto";
  /* box-shadow: 1px 4px 20px #3a384729; */
  -webkit-box-shadow: 10px 10px 5px 0px #3a384729;
  -moz-box-shadow: 10px 10px 5px 0px #3a384729;
  box-shadow: 10px 10px 5px 0px #3a384729;
}

@media only screen and (max-width: 1025px) {
  .media-content1 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    pointer-events: "auto";
    -webkit-box-shadow: 10px 10px 5px 0px #3a384729;
    -moz-box-shadow: 10px 10px 5px 0px #3a384729;
    box-shadow: 10px 10px 5px 0px #3a384729;
  }
}

.overlap-group {
  height: 89px;
  left: 195px;
  position: absolute;
  top: 150px;
  width: 58px;
}

.text-container {
  /* margin: 20px 20px; */
  /* box-shadow: 1px 4px 20px #3a384729; */
  overflow: hidden;
  /* min-height:320px; */
  /* height: 100%; */
}

.overlap-image {
  width: 2em; /* Adjust the size as needed */
  height: auto;
  margin-left: 0.5rem; /* Adjust the spacing as needed */
  vertical-align: middle; /* Align with text */
}

.group-container {
  height: 226px;
  position: relative;
  width: 358px;
}

.group-img {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 29.92rem; /* 29.92px */
  box-shadow: 3px 10px 4px #3a384740;
  display: flex;
  height: 60px;
  justify-content: center;
  left: 0;
  min-width: 60px;
  position: absolute;
  top: 10rem;
}

.overlap-group2-1 {
  align-items: flex-start;
  border: 5px dashed;
  border-color: #406736;
  border-radius: 32.42rem;
  display: flex;
  height: 65px;
  margin-top: -0.2rem;
  min-width: 65px;
  padding: 0.9rem 0.6rem; 
}

.bottom-container {
  justify-content: center;
  align-items: center;
  background-color: var(--pink-pearl);
  padding: 20px 20px;
}

/*-----------------------------------
  Contact us page
----------------------------------- */

.contact-container {
  width: 100% !important;
  margin-top: 4em;
}

.contact-card-container {
  align-items: center;
  justify-content: center;
  /* background-color: var(--black-squeeze); */
  padding: 20px 20px;
  margin: 20px 20px;
}

.contact-card-text-container {
  margin: 20px 20px;
  background-color: transparent !important;
  /* box-shadow: 1px 4px 20px #3a384729; */
  overflow: hidden;
  min-height: 30rem;
  /* height: 100%; */
}

.contact-card-img-container {
  display: flex;
  /* background: #ffffff; */
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  /* padding: 30px 0px 40px 0px; */
  pointer-events: none; 
  justify-content: flex-start;
  padding: 1.5rem;
}

.contact-card-body {
  padding: 1.5rem;
  text-align: left;
}

.contact-card-title {
  font-family: var( --font-family-public_sans );
  color: var(--ship-gray) !important;
  font-size: 1.8rem; 
  font-weight: 800;
  font-style: normal;
}

.underline-link {
  color: var(--ship-gray);
  font-family: var(--font-family-quicksand);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}

.contact-card-link {
  color: var(--orient);
  font-family: var(--font-family-quicksand);
  font-size: 1.2em;
  font-style: normal;
  font-weight: 700;
}

@media only screen and (max-width: 1025px) {
  .contact-container {
    width: 90% !important;
    margin-top: 3em;
  }
}

@media only screen and (max-width: 1025px) {
  .contact-card-text-container {
    margin: 20px 20px;
    background-color: transparent !important;
    /* box-shadow: 1px 4px 20px #3a384729; */
    overflow: hidden;
    min-height: 25rem;
    /* height: 100%; */
  }
}

/* --------------------------------------
 News page
------------------------------------------ */
.news-container {
  width: 90% !important;
  margin-top: 4em;
}

.news-overlap-group {
  align-items: flex-start;
  background-color: var(--alto-2);
  border-radius: 30rem;
  display: flex;
  min-height: 2em;
  min-width: 12em;
  width: 10em;
  padding: 1em 1em;
  margin: 0em 1em 1em 0em;
}

.news-posted {
  color: var(--orient);
  font-family: var(--font-family-quicksand);
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: -0.61px;
  line-height: normal;
  min-height: 20px;
  align-self: center;
}


.news-card-container {
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  margin: 0px !important;
  /* height: 100%; */
}

@media only screen and (max-width: 767px) {
  .news-container {
    width: 90% !important;
    margin-top: 3em;
  }
  .news-overlap-group {
    align-items: flex-start;
    background-color: var(--alto-2);
    border-radius: 28rem;
    display: flex;
    min-height: 2em;
    min-width: 10em;
    width: 10em;
    padding: 1em 1em;
    margin: 0em 1em 1em 0em;
  }
  .news-posted {
    color: var(--orient);
    font-family: var(--font-family-quicksand);
    font-size: 0.8em;
    font-weight: 500;
    letter-spacing: -0.61px;
    line-height: normal;
    min-height: 20px;
    align-self: center;
  }
}

.news-image-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.news-image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

/* --------------------------------------
 menu page
------------------------------------------ */
.menu-nav-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-arrow-container {
  display: flex;
  align-items: center;
  width: 100%;
  /* margin-left: 1em; */
  justify-content: flex-end;
  /* padding-right: 1em; */
}

.img-arrow {
  align-self: flex-end;
  align-items: center;
}

.menu-nav-link-container {
  display: flex !important;
  justify-content:center;
  cursor: pointer;
}

.menu-rectangle-purple {
  background-color:var(--purple-plum); /*  #71008e; */
  min-height: 72px;
  margin-left: -1rem;
  /* margin-top: -10px; */
  width: 2rem;
}

.menu-rectangle-white {
  background-color:var(--white); /*  #71008e; */
  min-height: 72px;
  margin-left: -1rem;
  /* margin-top: -10px; */
  width: 2rem;
}

.menu-content {
  /* font-family: var( --font-family-quicksand );
  font-size: 18px;
  font-weight: 700; 
  font-style: normal; 
  color: var(--ship-gray); */
  /* letter-spacing: -0.68px; */
  line-height: normal;
  margin-left: 1rem;
  margin-top: 1em;
  min-height: 23px;
  min-width: 72px;
  text-align: center;
  white-space: nowrap;
}

.bold-text {
  font-family: var( --font-family-quicksand );
  font-size: 18px;
  font-weight: 700; 
  font-style: normal; 
  color: var(--ship-gray);
}

.normal-text {
  font-family: var( --font-family-quicksand );
  font-size: 18px;
  font-weight: 500; 
  font-style: normal; 
  color: var(--ship-gray);
}